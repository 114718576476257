import { render, staticRenderFns } from "./goodscode-popover.vue?vue&type=template&id=bf7f06fc&scoped=true&"
import script from "./goodscode-popover.vue?vue&type=script&lang=js&"
export * from "./goodscode-popover.vue?vue&type=script&lang=js&"
import style0 from "./goodscode-popover.vue?vue&type=style&index=0&id=bf7f06fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf7f06fc",
  null
  
)

export default component.exports