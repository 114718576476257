<template>
  <el-popover placement="top" trigger="click" width="260">
    <el-form size="mini">
      <el-form-item label="名称">
        {{ goods.goodsName }}
      </el-form-item>
      <el-form-item label="类别">
        {{ goods.shopsCategoryName }}
      </el-form-item>
      <el-form-item label="零售价">
        {{ goods.retailPrice }}
      </el-form-item>
      <el-form-item label="市场价">
        {{ goods.marketPrice }}
      </el-form-item>
      <el-form-item label="预售">
        {{ goods.isPresale ? '是' : '否' }}
      </el-form-item>
      <el-form-item label="订单ID">
        <span class="orderId">{{ goods.orderId || '无' }}</span>
      </el-form-item>
      <el-form-item
        v-for="(name, i) in specificationNames"
        :key="i"
        :label="name"
      >
        {{ specificationValues[i] }}
      </el-form-item>
      <el-form-item v-if="goods.isPresale" label="取餐时间">
        {{ goods.pickupStartTime }} - {{ goods.pickupEndTime }}
      </el-form-item>
      <el-form-item label="图片">
        <img
          style="height: 50%; width: 50%"
          :src="goods.listPicUrl"
          @click="openImg(goods.listPicUrl)"
        />
      </el-form-item>
    </el-form>
    <slot />
    <el-tag slot="reference" effect="plain" :type="tagType(goods)" size="mini">
      {{ goods.goodsName }}
    </el-tag>
  </el-popover>
</template>

<script>
export default {
  name: 'GoodscodePopover',
  props: {
    goods: {
      type: Object,
      required: true,
    },
    updateCodeId: {
      type: String,
      required: true,
    },
    highlightGoodsId: {
      type: String,
      required: true,
    },
  },
  computed: {
    specificationNames() {
      return (
        this.goods &&
        this.goods.specificationNames &&
        this.goods.specificationNames.split(',')
      );
    },
    specificationValues() {
      return (
        this.goods &&
        this.goods.specificationValues &&
        this.goods.specificationValues.split(',')
      );
    },
  },
  methods: {
    tagType(code) {
      if (this.updateCodeId === code.id) {
        return 'warning';
      } else {
        if (code.orderId) {
          return 'danger';
        } else if (this.highlightGoodsId === code.goodsId) {
          return 'primary';
        } else {
          return 'info';
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.orderId {
  font-size: 8px;
}
</style>
